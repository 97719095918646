import { API_URL } from '../constants';
import {_dispatch, _axios} from '../utilities';
import axios from 'axios';
export {_dispatch};
export const SAVE_AUTH = 'SAVE_AUTH';
export const UPDATE_USER_PHONENUMBER = 'UPDATE_USER_PHONENUMBER';
export const UPDATE_KYC_STATUS = 'UPDATE_KYC_STATUS';
export const UPDATE_USERNAME_SUCCESS = 'UPDATE_USERNAME_SUCCESS';
export const UPDATE_USEREMAIL_SUCCESS = 'UPDATE_USEREMAIL_SUCCESS';

export function login($this, data) {
  return (dispatch) => { 
    return _axios({
      method: 'POST',
      data,
      url: `${API_URL}/user/login`
    }, $this).then((response) => {
      // Successful login
      dispatch({
        type: SAVE_AUTH,
        payload: response.data.data
      });
    });
  };
}

export function logout($this){
   return (dispatch) => { 
  	dispatch({
	    type: SAVE_AUTH,
	    payload: {}
	  });
  }
}

export function createGuestToken(params){
  return (dispatch) => { 
    // dispatch({
    //   type: SAVE_AUTH,
    //   payload: {}
    // });
    return _axios({
      method: 'GET',
      params: params,
      url: `${API_URL}/user/getGuestToken`
    }).then((response) => {
      dispatch({
        type: SAVE_AUTH,
        payload: response.data.data
      });
    });
  }
}

export function registerUser($this, data) {
  return (dispatch) => { 
    return _axios({
      method: 'POST',
      data,
      url: `${API_URL}/user/signUp`
    }, $this).then((response) => {
      // Successful login
      if(response.data.data && response.data.data.token){
        dispatch({
          type: SAVE_AUTH,
          payload: response.data.data
        });
      }
    });
  };
}

export function editPhoneNumber(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/editPhoneNumber`
  });
}

export function updateUserPhoneNumber(countryCode, phoneNumber) {
  return {
    type: UPDATE_USER_PHONENUMBER,
    countryCode: countryCode,
    phoneNumber: phoneNumber
  };
}

export function updateKycStatus(payload){
  return {
    type: UPDATE_KYC_STATUS,
    payload: payload
  };
}

export function updateUserName(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/editProfile`
  });
}

export function updateUserNameSuccess(payload){
  return {
      type: UPDATE_USERNAME_SUCCESS,
      payload: payload
    }
}


export function sendEditPhoneVerification(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/sendEditPhoneVerification`
  });
}

export function applyKycManually(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/applyKycManually`
  });
}

export function updateUserEmail(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/updateEmail`
  });
}

export function updateUserEmailSuccess(payload){
  return {
      type: UPDATE_USEREMAIL_SUCCESS,
      payload: payload
    }
}