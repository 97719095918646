import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import User from './user';
import Auth from './auth';
import Blog from './blog';

const appReducer = combineReducers({
  form: formReducer,
  User,
  Auth,
  Blog
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;