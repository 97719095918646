import React, { Component } from 'react';

class ContentLoader extends Component {
  render() {
    return (
       <div className="loader-wrapper">
            <div className="loader-inner-wrapper">
             	<div className="loader-circle"></div>
          		<div className="loader-line-mask">
             		<div className="loader-line"></div>
          		</div>
          		<img className="loader-icon" src="/assets/images/logo-img.png" alt="Loading..."/>
            </div>
      </div>
    );
  }
}

export default ContentLoader;
