import {
  _dispatch,
  USER_COMMISSION_KYC
} from '../actions/user';

const INITIAL_STATE = {commission: {}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
  	case USER_COMMISSION_KYC:
       return _dispatch({ ...state, commission: action.payload}, true, 'kyc_commission');
      break;
    
    default:
      return state;
  }
}