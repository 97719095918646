import {load} from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  HOME: { 
   path: '/',
   exact: true,
   component: load('LandingPage'),
  },
  AUTH: { 
   path: '/',
   exact: false,
   component: load('user/Auth'),
  },
  SIGNUP: { 
    path: '/sign-up',
    exact: true,
    component: load('Signup')
  },
  LOGIN: { 
    path: '/login',
    exact: true,
    component: load('Login')
  },
  FORGOTPASSWORD: { 
    path: '/forgot-password',
    exact: true,
    component: load('ForgotPassword')
  },
  MYTRANSACTIONS: { 
    path: '/transactions',
    exact: true,
    component: load('user/MyTransactions')
  },
  MYWALLET: { 
    path: '/wallet',
    exact: true,
    component: load('user/MyWallet')
  },
  REFERTOFRIEND: { 
    path: '/refertofriend',
    exact: true,
    component: load('user/ReferToFriend')
  },
  MONEYTRANFER: { 
    path: '/transfer-money/:index?',
    exact: true,
    component: load('user/TransferMoney')
  },
  KYCVERIFICATIONMETHOS: { 
    path: '/kyc-verification',
    exact: true,
    component: load('user/KycVerification')
    //component: load('user/KycVerificationMethod')
  },
  KYCAPPVERIFICATIONMETHOS: { 
    path: '/appkyc-verification',
    exact: true,
    component: load('user/AppKycVerification')
    //component: load('user/KycVerificationMethod')
  },
  KYCMANUALVERIFICATION: { 
    path: '/kyc-manual-verification',
    exact: true,
    component: load('user/VerifyKycManually')
    //component: load('user/KycVerificationMethod')
  },
  KYCDRIVERLICENSE: { 
    path: '/kyc-driver-license',
    exact: true,
    component: load('user/KycDriverLicenes')
  },
  USERPROFILE: { 
    path: '/profile',
    exact: true,
    component: load('user/Profile')
  },
  FAQLISTING: { 
    path: '/faq',
    exact: true,
    component: load('common/FaqListing')
  },
  ABOUTUS: { 
    path: '/about-us',
    exact: true,
    component: load('common/AboutUs')
  },
  CONTACTUS: { 
    path: '/contact-us',
    exact: true,
    component: load('common/ContactUs')
  },
  PRIVACYPOLICY: {
    path: '/privacy-policy',
    exact: true,
    component: load('pages/PrivacyPolicy')
  },
  TERMS: {
    path: '/terms-condition',
    exact: true,
    component: load('pages/Terms')
  },
  LOGOUT: { 
    path: '/logout',
    exact: true,
    component: load('Logout')
  },
  PAYMENTSUCCESS: { 
    path: '/paymentsuccess',
    exact: true,
    component: load('PaymentSuccess'),
  },
  DIRECTPAYMENTSUCCESS: { 
    path: '/directpaymentsuccess/:index',
    exact: true,
    component: load('DirectPaymentSuccess'),
  },
  PAYMENTFAILURE: { 
    path: '/paymentfailure',
    exact: true,
    component: load('PaymentFailure'),
  },
  PAYMENTCANCEL: { 
    path: '/paymentcancel',
    exact: true,
    component: load('PaymentCancel'),
  },
   PAYMENTNOTIFICATION: { 
    path: '/notification',
    exact: true,
    component: load('PaymentNotification'),
  },
  PAYMENTREDIRECTION: {
    path: '/redirect-gateway',
    exact: true,
    component: load('pages/RedirectGateway')
  },

  BLOGLISTING: {
    path: '/blogs',
    exact: true,
    component: load('blog/BlogListing')
  },
  BLOGDETAIL: {
    path: '/blogdetail/:slug',
    exact: true,
    component: load('blog/BlogDetail')
  },

  NOTFOUND: { 
    component: load('NotFound')
  }
};