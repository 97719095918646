import React, { Component, Suspense } from 'react';
import {connect} from 'react-redux';
//import * as io from "socket.io-client";

class VerifyUserLocation extends Component {
  constructor(props){
    super(props);
    //this.socket = null;
  }

  componentDidMount(){
    
  }
  shouldComponentUpdate(nextProps, nextState){
    return false;
  }
  render() {

    return null;
  }
}

export default VerifyUserLocation;