import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import {ROUTES} from './routes';
import ScrollToTop from 'react-router-scroll-top';

class Wrapper extends Component {
  
  render() {
    return (
      <ScrollToTop>
        <Switch>
          <Route {...ROUTES.HOME} />
          <Route {...ROUTES.FAQLISTING} />
          <Route {...ROUTES.BLOGLISTING} />
          <Route {...ROUTES.BLOGDETAIL} />
          <Route {...ROUTES.SIGNUP} />
          <Route {...ROUTES.LOGIN} />
          <Route {...ROUTES.KYCAPPVERIFICATIONMETHOS} />
          <Route {...ROUTES.FORGOTPASSWORD} />
          <Route {...ROUTES.ABOUTUS} />
          <Route {...ROUTES.TERMS} />
          <Route {...ROUTES.PRIVACYPOLICY} />
          <Route {...ROUTES.PAYMENTREDIRECTION} />
          <Route {...ROUTES.CONTACTUS} />
          <Route {...ROUTES.LOGOUT} />
          <Route {...ROUTES.AUTH} />

        </Switch>
      </ScrollToTop>
    );
  }
}


export default Wrapper;