import axios from 'axios';
import { API_URL } from '../constants';
import {_dispatch} from '../utilities';
export {_dispatch};

export const USER_COMMISSION_KYC = 'USER_COMMISSION_KYC';

export function saveUserFirstStep(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/sendPhoneVerification`
  });
}

export function resendVerificationCode(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${API_URL}/user/sendPhoneVerificationCode`
  });
}

export function postContactForm(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/contactUsForm`
  });
}


export function getFaq(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getFaqs`
  });
}

export function rateFaq(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/rateFaq`
  });
}

export function verifyPhoneLogin(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/phoneExist`
  });
}

export function sendEditPhoneVerificationCode(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/sendEditPhoneVerificationCode`
  });
}



export function userLogout(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${API_URL}/user/logout`
  })
}

export function sendForgotPasswordVerificationCode(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/sendForgotPasswordVerification`
  });
}

export function forgotPassword(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/forgotPassword`
  });
}

export function changePassword(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/changePassword`
  });
}

export function kycVerification(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/kyc_verification`
  });
}

export function getAppKycVerificationToken(token){
  return axios({
    method: 'GET',
    headers: {Authorization: token},
    url: `${API_URL}/user/getKycVerificationToken`
  });
}

export function getKycVerificationToken(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getKycVerificationToken`
  });
}

export function transferMoney(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/transferMoney`
  });
}

export function getTransferFees(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getCharges`
  });
}

export function getCountryList(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getCountries`
  });
}

export function kycRetrieveDetails(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/kycRetrieveDetails`
  });
}

export function getTransaction(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getTransaction`
  });
}

export function getTestimonial(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getTestimonials`
  });
}

export function updateTransactionStatus(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/updateTransactionStatus`
  });
}

export function exchangeRateConversion(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/exchangeRateConversion`
  })
}

export function getKycDetails(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getKycDetails`
  });
}

export function getAdminAccountInfo(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getAdminAccountInfo`
  });
}

export function getTransactionById(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getTransactionById`
  });
}
export function transactionFeedback(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/transactionFeedback`
  })
}
export function getWalletTransaction(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getWalletTransaction`
  });
}

export function getOccupation(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getOccupationList`
  });
}

export function getCountryCurrencyCharges(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getCountryCurrencyCharges`
  });
}

export function getAdminSetting(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getAdminSetting`
  });
}

export function getDateOfBirth(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getDateOfBirth`
  });
}

export function getKycStatusAndAdminCommissionCharges(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getKycStatusAndAdminCommissionCharges`
  });
}

export function getKycStatusAndAdminCommissionChargesSuccess(payload){
  return {
      type: USER_COMMISSION_KYC,
      payload: payload
    }
}

export function getPaymentTypeStatus(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getConstantData`
  });
}

export function getPromotion(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/user/getPromotionListing`
  });
}

export function acceptTerms(data) {
  return axios({
    method: 'PATCH',
    data: data,
    url: `${API_URL}/user/updateTemsCondition`
  });
}

export function getZaiPayId(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${API_URL}/zai/payId`
  });
}

