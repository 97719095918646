import { API_URL } from '../constants';
import {_dispatch, _axios} from '../utilities';
import axios from 'axios';

export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_COMMENT_LIST_SUCCESS = 'BLOG_COMMENT_LIST_SUCCESS';
export {_dispatch};


export function getBlogListing( payload) {
  return axios({
      method: 'GET',
      params: payload,
      url: `${API_URL}/user/getBlogs`,
  });
}

export function getBlogListingSuccess(payload){
  return {
      type: `BLOG_LIST_SUCCESS`,
      payload: payload
    }
}

export function getBlogDetail( payload) {
  return axios({
      method: 'GET',
      params: payload,
      url: `${API_URL}/user/getBlogDetails`,
  });
}

export function getBlogComment( payload) {
  return axios({
      method: 'GET',
      params: payload,
      url: `${API_URL}/user/getBlogComments`,
  });
}

export function getBlogCommentSuccess(payload){
  return {
      type: `BLOG_COMMENT_LIST_SUCCESS`,
      payload: payload
    }
}

export function addComment(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/addBlogComment`
  });
}
