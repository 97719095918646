import {
  SAVE_AUTH,
  UPDATE_USER_PHONENUMBER,
  UPDATE_KYC_STATUS,
  UPDATE_USEREMAIL_SUCCESS,
  UPDATE_USERNAME_SUCCESS,
  _dispatch
} from '../actions/auth';

const INITIAL_STATE = {user: {}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case SAVE_AUTH:
      return _dispatch({ ...state, user: action.payload}, true, 'Auth');
      break;
    case "CLEARSESSION":
    	return _dispatch({ ...state, user: {}}, true, 'Auth');
      break;
    case UPDATE_USER_PHONENUMBER:
      return _dispatch({ ...state, user: {...state.user, country_code: action.countryCode, mobile_number: action.phoneNumber}}, true, 'Auth');
      break;
    case UPDATE_KYC_STATUS:
      return  _dispatch({ ...state, user: {...state.user, kyc_status: action.payload}}, true, 'Auth');
      break;
    case UPDATE_USERNAME_SUCCESS:
      return  _dispatch({ ...state, user: {...state.user, name: action.payload}}, true, 'Auth');
      break;
    case UPDATE_USEREMAIL_SUCCESS:
      return  _dispatch({ ...state, user: {...state.user, email: action.payload}}, true, 'Auth');
      break;
    default:
      return state;
  }
}