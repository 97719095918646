import {
  _dispatch,
  BLOG_LIST_SUCCESS,
  BLOG_COMMENT_LIST_SUCCESS
} from '../actions/blog';

const INITIAL_STATE = {blog_list: {}, comment_list:{}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
  	case BLOG_LIST_SUCCESS:
       return _dispatch({ ...state, blog_list: action.payload}, true, 'bloglist');
      break;
    case BLOG_COMMENT_LIST_SUCCESS:
       return _dispatch({ ...state, comment_list: action.payload}, true, 'commentlist');
      break;
    default:
      return state;
  }
}